import { Renderer } from '@unseenco/taxi';
import gsap from 'gsap';

export default class HomeRenderer extends Renderer {
  onEnter() {
    // run after the new content has been added to the Taxi container
    gsap.set('.h2', { y: '100%' });
    gsap.set('.h1', { y: '100%' });
    gsap.set('.homepage-social li', { autoAlpha: 0 });
    gsap.set('.homepage-grid__main .button', { autoAlpha: 0 });
    gsap.set('.homepage-grid__main .asterisk-content', { autoAlpha: 0 });
}

onEnterCompleted() {
    // run after the transition.onEnter has fully completed
    const tl = gsap.timeline({delay: 1.4});

    tl.to('.homepage-grid__tr',
    {
      scaleY: 0,
      duration: 0.8,
      ease: "circ.out"
    });

    tl.to('.homepage-grid__bl',
    {
      scaleY: 0,
      duration: 0.8,
      ease: "circ.out"
    }, '>-0.8');

    tl.to('.h1',
    {
      y: 0,
      delay: 0.01,
      duration: 0.4,
      ease: "circ.out"
    });

    tl.to('.h2',
    {
      y: 0,
      duration: 0.4,
      ease: "circ.out"
    },
    '>-0.2',
    );

    tl.to('.homepage-social li',
    {
      autoAlpha: 1,
      duration: 3,
      ease: "circ.out",
      stagger: 0.1,
    });

    tl.to('.homepage-grid__main .button',
    {
      autoAlpha: 1,
      duration: 3,
      ease: "circ.out"
    },'>-2.8');

    tl.to('.homepage-grid__main .asterisk-content',
    {
      autoAlpha: 1,
      duration: 1,
      ease: "circ.out"
    }, '>-2');
}

onLeave() {
    // run before the transition.onLeave method is called
    console.log('on leave')
}

onLeaveCompleted() {
    // run after the transition.onleave has fully completed
    console.log('on leave completed')
  }
}