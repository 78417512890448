import { Core } from '@unseenco/taxi'

// Renderers
import HomeRenderer from './renderers/HomeRenderer.js';

// Transitions
import DefaultTransition from './transitions/DefaultTransition.js';

export default class Routing {
    constructor(){
        this.taxi = new Core({
            renderers: {
                home: HomeRenderer,
            },
            transitions: {
                default: DefaultTransition,
            }
        });
    }
}