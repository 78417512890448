import EventEmitter from "./EventEmitter";

import Experience from "../Experience";

class Mouse extends EventEmitter {
  constructor() {
    super();

    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);

    this.experience = new Experience();
    this.sizes = this.experience.sizes;
    this.infos = {};
    this.infos.grabbing = { value: false };
    this.infos.mouseCoordinates = { x: 0, y: 0 };
    this.infos.negToPosMouseCoordinates = { x: -1, y: 1 };
    this.infos.normalizedMouseCoordinates = { x: 0, y: 0 };
    this.infos.mouseDownCoordinates = { x: 0, y: 0 };

    window.addEventListener("mousemove", this.handleMouseMove);
    window.addEventListener("mousedown", this.handleMouseDown);
    window.addEventListener("mouseup", this.handleMouseUp);
  }

  handleMouseDown() {
    this.infos.grabbing = {
      value: true,
    };

    this.infos.mouseDownCoordinates = this.infos.negToPosMouseCoordinates;

    this.trigger("mousedown");
  }

  handleMouseUp() {
    this.infos.grabbing = {
      value: false,
    };

    this.trigger("mouseup");
  }

  getMouseCoordinates(event) {
    if (!event) return;
    event.preventDefault();

    this.infos.mouseCoordinates = {
      x: event.clientX,
      y: event.clientY,
    };
  }

  /**
   * Return value between -1 and 1
   */
  getMouseNegToPoCoordinates(event) {
    if (!event) return;
    event.preventDefault();

    this.infos.negToPosMouseCoordinates = {
      x: (event.clientX / this.sizes.width) * 2 - 1,
      y: -(event.clientY / this.sizes.height) * 2 + 1,
    };
  }

  getNormalizedMouseCoordinates(event) {
    if (!event) return;

    event.preventDefault();

    this.infos.normalizedMouseCoordinates = {
      x: event.clientX / this.sizes.width,
      y: event.clientY / this.sizes.height,
    };
  }

  handleMouseMove(event) {
    this.getMouseCoordinates(event);
    this.getMouseNegToPoCoordinates(event);
    this.getNormalizedMouseCoordinates(event);

    this.trigger("mousemove");
  }
}

export default Mouse;
