import Experience from '../Experience.js';
import Homepage from './Homepage.js';
import gsap from 'gsap';

export default class World
{
    constructor()
    {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.resources = this.experience.resources;
        this._loader = document.querySelector('.loader');

        // Wait for resources
        this.resources.on('ready', () =>
        {
            // Setup
            this.homepage = new Homepage();

            gsap.to(this._loader, {
                autoAlpha: 0,
                duration: 0.4,
                delay: 0.6,
                ease: "sine.out",
            });

        })
    }

    update()
    {
        if(this.homepage){
            this.homepage.update();
        }
    }
}