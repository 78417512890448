import * as THREE from 'three';
import gsap from 'gsap';
import Experience from '../Experience.js';

import vertex from '../shaders/vertex.glsl';
import fragment from '../shaders/fragment.glsl';

const devicesSize = {
  desktop: 'desktop',
  mobile: 'mobile'
}

export default class Homepage
{
    constructor()
    {
        this.experience = new Experience();
        this.scene = this.experience.scene;
        this.sizes = this.experience.sizes;

        this.mouse = this.experience.mouse;

        this.timeFrequency = 0.00009;
        this.geometry = new THREE.PlaneGeometry(2.2, 2.2, 320, 320);

        this.disc = document.querySelector('.coming-soon');
        this.button = document.querySelector('[data-csbtn]');
        this.content = document.querySelectorAll('.homepage-grid__main > *');

        this.discCoor = { x: 0, y: 0}

        this.material1 = new THREE.ShaderMaterial({
            vertexShader: vertex,
            fragmentShader: fragment,
            uniforms: {
                uDistortionFrequency: {
                  value: 6.213,
                },
                uDistortionStrength: {
                  value: 2.539,
                },
                uDisplacementFrequency: {
                  value: 2.298,
                },
                uDisplacementStrength: {
                  value: 0.1,
                },
                uTime: {
                  value: 0,
                },
                uIntensity: {
                  value: 1,
                },
                uColor1: {
                    value: new THREE.Color('#02001f'),
                },
                uColor2: {
                    value: new THREE.Color('#0090a3'),
                },
                uColor3: {
                    value: new THREE.Color('#ff7b00'),
                },
                uColor4: {
                    value: new THREE.Color('#ffffff'),
                },
                uPos: {
                  value: new THREE.Vector2(0,0)
                }
              },
        });
        this.material2 = this.material1.clone();
        this.mesh1 = new THREE.Mesh(this.geometry, this.material1);
        this.mesh2 = new THREE.Mesh(this.geometry, this.material2);
        this.scene.add(this.mesh1, this.mesh2);

        this.button.addEventListener('mouseenter', () => this.showDisc())
        this.button.addEventListener('mouseleave', () => this.hideDisc())

        this.currentDeviceSize = this.sizes.width > 768 ? devicesSize.desktop : devicesSize.mobile;

        if(this.sizes.width > 768){
          this.setDesktopConfig();
        } else {
          this.setMobileConfig();
        }

        // Resize event
        this.sizes.on('resize', () => {
          if(this.sizes.width > 768 && this.currentDeviceSize === devicesSize.mobile){
            this.currentDeviceSize = devicesSize.desktop;
            this.setDesktopConfig();
          } else if (this.sizes.width < 769 && this.currentDeviceSize === devicesSize.desktop){
            this.currentDeviceSize = devicesSize.mobile;
            this.setMobileConfig();
          }
        });
    }

    showDisc(){
      this.disc.classList.add('visible');
      
      gsap.to(this.content, {
        autoAlpha: 0.3,
        duration: 0.3,
        ease: "power1.inOut",
      });
    }

    hideDisc(){
      this.disc.classList.remove('visible');

      gsap.to(this.content, {
        autoAlpha: 1,
        duration: 0.3,
        ease: "power1.inOut",
      });
    }

    setDesktopConfig(){
      this.mesh1.material.uniforms.uPos.value = new THREE.Vector2(1,4.5);
      this.mesh2.material.uniforms.uPos.value = new THREE.Vector2(-1,-4.5);
    }

    setMobileConfig(){
      this.mesh1.material.uniforms.uPos.value = new THREE.Vector2(0,4.5);
      this.mesh2.material.uniforms.uPos.value = new THREE.Vector2(0,-4.5);
    }

    update()
    {
        const elapsedTime = this.experience.time.elapsed;

        this.discCoor.x += (this.mouse.infos.mouseCoordinates.x - this.discCoor.x) * 0.1;
        this.discCoor.y += (this.mouse.infos.mouseCoordinates.y - this.discCoor.y) * 0.1;
        this.disc.style.transform = `translate3d(${this.discCoor.x}px, ${this.discCoor.y}px, 1px)`;

        this.material1.uniforms.uTime.value = elapsedTime * this.timeFrequency;
        this.material2.uniforms.uTime.value = elapsedTime * this.timeFrequency;
    }
}