import { Transition } from '@unseenco/taxi'
import gsap from 'gsap'

export default class DefaultTransition extends Transition {
  /**
   * Handle the transition entering the next page.
   * @param { { to: HTMLElement, trigger: string|HTMLElement|false, done: function } } props
  */
 onEnter({ to, trigger, done }) {
   
   // Remove Old View
   from.remove();
   
   // Animation
   gsap.fromTo(
     to,
     { opacity: 0 },
     {
       opacity: 1,
       duration: 0.8,
       delay: 0.3,
       onComplete: done,
      }
      );
    }
    
    /**
     * Handle the transition leaving the previous page.
     * @param { { from: HTMLElement, trigger: string|HTMLElement|false, done: function } } props
    */
  onLeave({ from, trigger, done }) {
    gsap.fromTo(
      from,
      { opacity: 1 },
      {
        opacity: 0,
        duration: 0.8,
        onComplete: done,
      }
    );

  }
}
